import { MAX_TRANSACTION } from 'src/constants';
import Order from './Order';
import Slot from './Slot';
import Label from './Label';

export default class Door {
  constructor(params) {
    const {
      id,
      name,
      isActive,
      isReserve,
      price,
      announcement,
      description,
      remark,
      queueLength,
      orders,
      isSold,
      labels,
      preordersCount,
      slots
    } = params ?? {};

    this.id = id;
    this.name = name;
    this.isActive = isActive;
    this.isReserve = isReserve;
    this.price = price;
    this.announcement = announcement;
    this.description = description;
    this.remark = remark;
    this.queueLength = queueLength;
    this.orders = orders?.map((order) => Order.fromRes(order)) ?? [];
    this.isSold = isSold;
    this.labels = labels?.map((label) => Label.fromRes(label)) ?? [];
    this.preordersCount = preordersCount;
    this.slots = slots?.map((slot) => Slot.fromRes(slot)) ?? [];
  }

  get isDisable() {
    return this.isReserve || this.isSold || this.queueLength >= MAX_TRANSACTION;
  }

  static fromRes(data) {
    return new Door(data);
  }
}
