import dayjs from 'dayjs';

import UserService from 'src/services/user';
import { Theme } from 'src/components/Modal/themes';
import ErrorMessageService from 'src/services/errorMessage';

export default class EcPay {

  didMount = async (props) => {
    await this.createForm(props);
  }

  createForm = async (props) => {
    const { history, profile, match, location, appContext } = props ?? {};

    try {
      const price = new URLSearchParams(location.search).get('price');
      const spacesPrice = new URLSearchParams(location.search).get('spacesPrice');
      const doorId = new URLSearchParams(location.search).get('doorId');

      const form = await UserService.createEcPayForm(match.params.uid, match.params.pid, price, spacesPrice);

      document.open();
      document.write(form);
      document.close();

      this.setTradesInterval(props, doorId);

    } catch (err) {

      appContext.actions.showModal({
        theme: Theme.warning,
        title: 'INFO',
        content: ErrorMessageService.createEcPayForm(err),
        okText: '確定',
        onOk: () => {
          // TODO 待討論
          history.push('/pickout');
        },
        onCancel: () => {
          // TODO 待討論
          history.push('/pickout');
        }
      });
    }
  }

  setTradesInterval = async (props, doorId) => {
    const { appContext, history, profile } = props ?? {};

    // set inerval to get trades
    const intervalId = setInterval(async () => {
      try {
        if (doorId) {
          const trades = await UserService.getTrades(doorId);
          const dealTrade = trades.filter((trade) => trade.status === '1').sort((a, b) => b.updatedAt - a.updatedAt).shift();
          if (dealTrade) {
            history.push(`/order/${dealTrade.transactionId}?t=${dayjs().valueOf()}`);
            clearInterval(intervalId);
          }
          console.log('dealTrades: ', dealTrade);
        }
      } catch (err) {
        clearInterval(intervalId);
        console.log('err: ', err);
      }
    }, 1000 * 15);
    this.timeId = setTimeout(() => clearInterval(intervalId), 1000 * 60 * 5);

  }
}
