import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import cSModule from 'src/services/csModule';
import salesPopup from 'src/services/salesPopup';
import guestCSForm from 'src/services/guestForm';

import CloseBtn from 'src/assets/CloseBtn.svg';

import styles from './styles.module.css';

@observer
class GuestCSForm extends React.Component {

  componentDidMount() {
    guestCSForm.didMount();
  }

  render() {
    return (
      <>
        <MediaQuery maxWidth={768}>
          <div className={clsx(styles.curtain, !guestCSForm.isOpen && styles.dn)} />
        </MediaQuery>
        <div className={clsx(styles.formContainer, !guestCSForm.isOpen && styles.noShow)}>
          <div
            className={clsx(styles.closBtn)}
            onClick={guestCSForm.toggleOpen}
          >
            <img src={CloseBtn} alt="CloseBtn" className={clsx(styles.closeIcon)} />
          </div>
          <div className={clsx(styles.header)}>
            買屋諮詢
          </div>
          <div className={clsx(styles.note)}>
            請提供資訊
          </div>

          <div className={clsx(styles.formRow)}>
            <div className={styles.rowHeader}>
              姓名※
            </div>
            <div className={clsx(styles.inputGrid)}>
              <input
                className={clsx(styles.inputContainer, guestCSForm.isLastNameIllegal && styles.illegalInput)}
                value={guestCSForm.guestLastName}
                onChange={guestCSForm.onLastNameChange}
                placeholder={guestCSForm.isLastNameIllegal ? '姓名不能為空' : '姓'}
              />
              <input
                className={clsx(styles.inputContainer, guestCSForm.isFirstNameIllegal && styles.illegalInput)}
                value={guestCSForm.guestFirstName}
                onChange={guestCSForm.onFirstNameChange}
                placeholder={guestCSForm.isFirstNameIllegal ? '姓名不能為空' : '名'}
              />
            </div>
          </div>

          <div className={clsx(styles.formRow)}>
            <div className={styles.rowHeader}>
              電子郵件地址
            </div>
            <input
              className={clsx(styles.inputContainer)}
              value={guestCSForm.guestEmail}
              onChange={guestCSForm.onEmailChange}
              // onKeyDown={guestCSForm.onKeyDownSubmit}
              placeholder="example@maauu.com"
            />
            {
              !guestCSForm.isEmailLegal && (
                <div className={styles.errorNote}>
                  格式錯誤
                </div>
              )
            }
          </div>
          <MediaQuery maxWidth={768}>
            <div
              className={clsx(styles.submitBtn)}
              onClick={() => guestCSForm.onSubmit(cSModule.guestStart, salesPopup.toggleCombineShow)}
            >
              <div className={clsx(styles.submitBtnText)}>
                開始討論
              </div>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={769}>
            <div
              className={clsx(styles.submitBtn)}
              onClick={() => guestCSForm.onSubmit(cSModule.guestStart, salesPopup.changeShow)}
            >
              <div className={clsx(styles.submitBtnText)}>
                開始討論
              </div>
            </div>
          </MediaQuery>


        </div>
      </>
    );
  }
}

GuestCSForm.propTypes = {

};

GuestCSForm.defaultProps = {

};

export default GuestCSForm;
