import React from 'react';
import { observer } from 'mobx-react';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import clsx from 'clsx';

import cSModule from 'src/services/csModule';
import salesPopup from 'src/services/salesPopup';
import guestCSForm from 'src/services/guestForm';

import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';

import CSIcon from 'src/assets/ChatIcon.svg';
import styles from './styles.module.css';


@observer
class CsBtn extends React.Component {
  constructor() {
    super();
  }

  render() {
    const userIdentity = this.props.appContext.state.profile?.group;
    return (
      <MediaQuery minWidth={769}>
        <div className={styles.modal}>
          {
            userIdentity !== 'sales' && (
              <div className={clsx(styles.outerContainer)}>
                <div
                  className={styles.csBtnContainer}
                  onClick={
                    userIdentity === 'consumer' || guestCSForm.isGuestLogin
                      ? salesPopup.changeShow
                      : guestCSForm.toggleOpen
                  }
                >
                  <img
                    className={clsx(styles.chatting)}
                    src={CSIcon}
                    alt="csBtn"
                  />
                  {
                    cSModule.unread !== 0 && (
                      <div className={styles.unread} />
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      </MediaQuery>
    );
  }
}

CsBtn.propTypes = {
  appContext: PropTypes.object
};

CsBtn.defaultProps = {
  appContext: null
};

export default withAppStateContext(CsBtn);
