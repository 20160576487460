import { request, getHost } from './utils';

export const otp = (number) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v2/users/otp`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },
    data: {
      number
    }
  };
  return request(options);
};

export const login = (id, password) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v2/users/login`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },
    data: {
      id,
      password
    }
  };
  return request(options);
};

export const signup = (req, token) => {
  // req = {
  //   "telephone": "string",
  //   "lastName": "string",
  //   "firstName": "string",
  //   "email": "string",
  //   "avatar": 0,
  //   "gender": 0,
  //   "job": "string",
  //   "birthday": 0,
  //   "postalCode": "string",
  //   "city": "string",
  //   "region": "string",
  //   "address": "string"
  // }

  const options = {
    method: 'post',
    url: `${getHost()}/api/v2/users/signup`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: req
  };
  return request(options);
};

export const logout = (token) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/users/logout`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const myProfile = (token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v2/users/me/profile`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const userProfile = (token, uid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v2/users/${uid}/profile`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const updateProfile = (token, uid, data) => {
  // req = {
  //   "telephone": "string",
  //   "group": "consumer",
  //   "lastName": "string",
  //   "firstName": "string",
  //   "isBlock": true,
  //   "email": "string",
  //   "avatar": 0,
  //   "gender": 0,
  //   "job": "string",
  //   "birthday": 0,
  //   "postalCode": "string",
  //   "city": "string",
  //   "region": "string",
  //   "address": "string"
  // }
  const options = {
    method: 'put',
    url: `${getHost()}/api/v2/users/${uid}/profile`,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data
  };
  return request(options);
};

export const createPreOrder = (token, uid, doorId) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/users/${uid}/preorder`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      doorId
    }
  };
  return request(options);
};

export const getPreOrder = (token, uid, pid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/users/${uid}/preorder/${pid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const updatePreOrder = (token, uid, pid, req) => {
  // req = {
  //   "style": "string",
  //   "paymentType": "direct",
  //   "ordererName": "string",
  //   "ordererInfo": "string",
  //   "remark": "string"
  //   "referrerId": number
  //   "garages": [SlotSpacePair]
  // }

  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/users/${uid}/preorder/${pid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: req
  };
  return request(options);
};

export const deletePreOrder = (token, uid, pid) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/users/${uid}/preorder/${pid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getOrders = (token, uid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/users/${uid}/orders`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getOrder = (token, uid, oid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/users/${uid}/orders/${oid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getOrderReceipt = (token, uid, oid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/users/${uid}/orders/${oid}/receipt`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const createEcPayForm = (token, uid, pid, price, spacesPrice) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v2/users/${uid}/orders`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      preorderId: pid,
      price,
      spacesPrice
    }
  };
  return request(options);
};

export const sales = (token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v2/users/sales`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const trades = (token, doorId) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/users/trades?door=${doorId}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};
