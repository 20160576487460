import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';
import withProfile from 'src/components/withProfile';
import EcPayViewModel from 'src/viewModels/EcPay';

@observer
class EcPay extends Component {
  constructor(props) {
    super(props);
    this.viewModel = new EcPayViewModel(props);
  }

  async componentDidMount() {
    await this.viewModel.didMount(this.props);
  }

  render() {
    return null;
  }
}

EcPay.defaultProps = {
  appContext: {}
};

EcPay.propTypes = {
  appContext: PropTypes.object
};

export default withProfile(withAppStateContext(withRouter(EcPay)), false);
