import { observable, action, makeObservable } from 'mobx';

import { SectionType } from 'src/constants';
import ISection from './ISection';

export default class SectionGViewModel extends ISection {
  @observable id;
  @observable infoCase = '';
  @observable info = '';
  @observable infoeng = '';
  @observable icon1 = '';
  @observable info1 = '';
  @observable icon2 = '';
  @observable info2 = '';
  @observable icon3 = '';
  @observable info3 = '';
  @observable infoSubTit1 = '';
  @observable infoSubTit2 = '';
  @observable map = '';
  // @observable shoppingbutton = [];

  get type() {
    return SectionType.G;
  }

  constructor(props) {
    super(props);
    // makeObservable(this);
    this.deserialize(props);
  }

  onEnter = () => { };

  onLeave = () => { };

  @action
  deserialize = (params) => {
    const {
      id,
      infoCase,
      info,
      infoeng,
      icon1,
      info1,
      icon2,
      info2,
      icon3,
      info3,
      infoSubTit1,
      infoSubTit2,
      map,
      // shoppingbutton
    } = params;

    this.id = id;
    this.infoCase = infoCase;
    this.info = info;
    this.infoeng = infoeng;
    this.icon1 = icon1;
    this.info1 = info1;
    this.icon2 = icon2;
    this.info2 = info2;
    this.icon3 = icon3;
    this.info3 = info3;
    this.infoSubTit1 = infoSubTit1;
    this.infoSubTit2 = infoSubTit2;
    this.map = map;
    // this.shoppingbutton = shoppingbutton;
  }
}
