import React from 'react';

import styles from './style.module.css';

const Radio = React.forwardRef((props, ref) => {
  const { id, label, style, className, ...others } = props;
  return (
    <>
      <label htmlFor={id} className={className} style={style}>
        <input className={styles.radio} type="radio" {...others} ref={ref} />
        <span className={styles.text}>{label}</span>
      </label>
    </>
  );
});

export default Radio;
