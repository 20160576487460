/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import is from 'is_js';
import isEmail from 'validator/lib/isEmail';
import { Button } from 'antd';

import { useForm } from 'react-hook-form';
import useTwZipCode from 'src/hooks/useTwZipCode';
import useBirth from 'src/hooks/useBirth';

import Input from 'src/components/Common/Input';
import Select from 'src/components/Common/Select';
import Checkbox from 'src/components/Common/Checkbox';
import styles from './styles.module.css';
import common from '../../common.module.css';


export default function SignupForm({ onSubmit, loading, onPrivacyClick, onCreditCardClick }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  const formRef = useRef();
  const { yearOptions, monthOptions, dayOptions, onMonthChange, onYearChange } = useBirth();
  const { cityOptions, districtOptions, onCityChange } = useTwZipCode();

  const yearRegistration = register('year', { required: true });
  const monthRegistration = register('month', { required: true });
  const dayRegistration = register('day', { required: true });
  const cityRegistration = register('city', { required: true });
  const districtRegistration = register('region', { required: true });
  const privacyRegistration = register('privacy', { required: true });

  const _convertBirth = (year, month, day) => {
    if (is.empty(year) || is.empty(month) || is.empty(day)) return null;
    const m = month < 10 ? `0${month}` : month;
    const d = day < 10 ? `0${day}` : day;
    const birth = `${year}${m}${d}`;
    return Number(birth);
  };

  const _onSubmit = useCallback(async (data) => {
    const req = {
      ...data,
      birthday: _convertBirth(data.year, data.month, data.day)
    };

    await onSubmit(req);
  }, [onSubmit]);

  return (
    <form ref={formRef} id="userCardForm" onSubmit={handleSubmit(_onSubmit)}>
      <div className={styles.row} style={{ marginTop: 40 }}>
        <div className={styles.form_title}>
          姓名
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.lastName}>
          <div className={errors.lastName ? styles.inputErrorContainer : styles.inputContainer}>
            <Input
              control={control}
              name="lastName"
              placeholder="姓"
              rules={{ required: true }}
              inputClassName={styles.form_input}
              error={errors.lastName}
            />
          </div>
        </div>

        <div className={styles.firstName}>
          <div className={errors.firstName ? styles.inputErrorContainer : styles.inputContainer}>
            <Input
              control={control}
              name="firstName"
              placeholder="名"
              rules={{ required: true }}
              inputClassName={styles.form_input}
              error={errors.firstName}
            />
          </div>
        </div>

      </div>

      <div className={styles.row} style={{ marginTop: 20 }}>
        <div className={styles.form_title}>
          電子郵件地址
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.email}>

          <div className={errors.email ? styles.inputErrorContainer : styles.inputContainer}>
            <Input
              control={control}
              name="email"
              placeholder="請輸入電子郵件地址"
              rules={{
                validate: (value) => isEmail(value)
              }}
              inputClassName={styles.form_input}
              error={errors.email}
            />
          </div>
        </div>
      </div>

      <div className={styles.row} style={{ marginTop: 20 }}>
        <div className={styles.form_title}>生日</div>
      </div>
      <div className={styles.row}>
        <div className={styles.form_title}>會員應為法人或其所在國家地域法定成年人，始得註冊為本網站會員。</div>
      </div>


      <div className={styles.row}>
        <div className={styles.year}>
          <div className={errors.year ? styles.inputErrorContainer : styles.inputContainer}>
            <Select
              className={styles.form_select}
              options={yearOptions}
              {...yearRegistration}
              onChange={(e) => {
                onYearChange(e.target.value);
                yearRegistration.onChange(e);
              }}
            />
          </div>
        </div>

        <div className={styles.month}>
          <div className={errors.month ? styles.inputErrorContainer : styles.inputContainer}>
            <Select
              className={styles.form_select}
              options={monthOptions}
              {...monthRegistration}
              onChange={(e) => {
                onMonthChange(e.target.value);
                monthRegistration.onChange(e);
                setValue('day', '');
              }}
            />
          </div>
        </div>

        <div className={styles.day}>
          <div className={errors.day ? styles.inputErrorContainer : styles.inputContainer}>
            <Select
              className={styles.form_select}
              options={dayOptions}
              {...dayRegistration}
              onChange={(e) => {
                dayRegistration.onChange(e);
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.row} style={{ marginTop: 20 }}>
        <div className={styles.form_title}>聯絡暨合約地址</div>
      </div>

      <div className={styles.row}>
        <div className={styles.city}>
          <div className={errors.city ? styles.inputErrorContainer : styles.inputContainer}>
            <Select
              className={styles.form_select}
              options={cityOptions}
              {...cityRegistration}
              onChange={(e) => {
                onCityChange(e.target.value);
                cityRegistration.onChange(e);
                setValue('region', '');
              }}
            />
          </div>
        </div>

        <div className={styles.region}>
          <div className={errors.region ? styles.inputErrorContainer : styles.inputContainer}>
            <Select
              className={styles.form_select}
              options={districtOptions}
              {...districtRegistration}
              onChange={(e) => {
                districtRegistration.onChange(e);
              }}
            />
          </div>
        </div>

        <div className={styles.postalCode}>
          <div className={errors.postalCode ? styles.inputErrorContainer : styles.inputContainer}>
            <Input
              control={control}
              rules={{ required: true }}
              name="postalCode"
              placeholder="郵遞區號"
              inputClassName={styles.form_input}
              error={errors.postalCode}
            />
          </div>
        </div>
      </div>

      <div className={styles.row} style={{ marginBottom: 0 }}>
        <div className={styles.address}>
          <div className={errors.address ? styles.inputErrorContainer : styles.inputContainer}>
            <Input
              control={control}
              rules={{ required: true }}
              name="address"
              placeholder="街道, 巷弄, 門號, 樓層"
              inputClassName={styles.form_input}
              error={errors.address}
            />
          </div>
        </div>
      </div>

      <div className={styles.privacy_layout}>
        <div className={styles.privacy}>
          <Checkbox id="privacy" {...privacyRegistration} className={clsx(errors.privacy && styles.checkbox_error)} />
          <div htmlFor="privacy" className={styles.privacy_label} onClick={onPrivacyClick}>
            建立帳戶，即表示本人了解並同意
            <span className={styles.privacy_popup}>
              隱私聲明與使用條款
            </span>
          </div>
        </div>
      </div>

      <div className={common.submit}>
        <Button className={styles.sign_up_button} htmlType="submit" loading={loading}>註冊</Button>
      </div>
    </form>
  );
}

SignupForm.defaultProps = {
  onSubmit: () => {},
  onPrivacyClick: () => { },
  onCreditCardClick: () => { },
  loading: false
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func,
  onPrivacyClick: PropTypes.func,
  onCreditCardClick: PropTypes.func,
  loading: PropTypes.bool
};
