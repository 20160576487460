import { useState } from 'react';

import { jobOptions } from './data';

export default function useJob() {
  const [job, setJob] = useState(jobOptions[0]);

  const onJobChange = (value) => {
    setJob(value);
  };

  return { jobOptions, onJobChange };
}
