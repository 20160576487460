import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import PageViewModel from 'src/viewModels/Advertisement/PageViewModel';
import MobileIndex from './components/MobileSections';
import WebIndex from './components/WebSections';

@observer
class Advertisement extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = new PageViewModel(this.props.handleChangeSection);
  }

  render() {
    return (
      <div className="page">
        <MediaQuery minWidth={769}>
          <WebIndex
            viewModel={this.viewModel}
          />
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <MobileIndex
            viewModel={this.viewModel}
          />
        </MediaQuery>
      </div>
    );
  }
}

Advertisement.propTypes = {
  handleChangeSection: PropTypes.func
};

Advertisement.defaultProps = {
  handleChangeSection: null
};

export default Advertisement;
