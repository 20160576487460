import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import cSModule from 'src/services/csModule';
import salesPopup from 'src/services/salesPopup';
import guestCSForm from 'src/services/guestForm';

import withProfile from 'src/components/withProfile';
import backIcon from 'src/assets/leftArrow.png';

import styles from './styles.module.css';
import salesChatIcon from '../../assets/salesChatIcon.svg';
import guestProfile from '../../assets/guestProfile.svg';
import consumerProfile from '../../assets/consumerProfile.svg';

@observer
class NavBar extends React.Component {
  componentDidUpdate() {
    const { profile } = this.props;

    this.props.viewModel.updateProfile(profile);
    if (profile && salesPopup.salesList.length === 0) {
      salesPopup.didUpdate();
    }
  }

  render() {
    const { unread, show } = cSModule;
    const { viewModel } = this.props;
    const { isShow } = viewModel;
    const profileImg
      = viewModel.profile?.group === 'sales'
      || viewModel.profile?.group === 'consumer'
        ? consumerProfile
        : guestProfile;
    const isPickPutPage
      = this.props.location.pathname === '/pickout'
      || this.props.location.pathname === '/preorder';

    const showBackBtn
      = this.props.location.pathname === '/preorder'
      || this.props.location.pathname === '/login';

    return (
      <>
        {/* web */}
        <MediaQuery minWidth={769}>
          <div className={styles.navBar}>
            <div className={styles.contentContainer}>
              {showBackBtn && (
                <div
                  className={styles.back_btn}
                  onClick={() => this.props.history.goBack()}
                >
                  <img src={backIcon} width={8} height={15} alt="" />
                </div>
              )}

              <Link to="/">
                <img
                  src={viewModel.logoImg}
                  alt="logo"
                  className={styles.logoImg}
                />
              </Link>
            </div>

            <div className={styles.contentContainer}>
              {!isPickPutPage && (
                <Link to="/pickout" className={styles.toPickOutContainer}>
                  <div className={styles.pickOutText}>買房</div>
                </Link>
              )}

              {viewModel.profile?.group === 'sales' ? (
                <div className={styles.serviceContainer} onClick={show}>
                  <img src={salesChatIcon} alt="salesChat" />
                  <div
                    className={clsx(
                      styles.notification,
                      styles.salesNotification,
                      unread !== 0 ? styles.show : null
                    )}
                  />
                </div>
              ) : null}

              <Link to="/center" className={styles.toCenterContainer}>
                <img
                  src={profileImg}
                  alt="member"
                  className={styles.memberImg}
                />
                <div className={styles.name}>
                  {this.props.profile?.lastName
                    + this.props.profile?.firstName || ''}
                </div>
              </Link>
            </div>
          </div>
        </MediaQuery>
        {/* mobile */}
        <MediaQuery maxWidth={768}>
          <div className={clsx(styles.navBar)}>
            <div className={styles.contentContainer}>
              {showBackBtn && (
                <div
                  className={styles.back_btn}
                  onClick={() => this.props.history.goBack()}
                >
                  <img src={backIcon} width={8} height={15} alt="" />
                </div>
              )}

              <Link to="/">
                <img
                  src={viewModel.logoImg}
                  alt="logo"
                  className={styles.logoImg}
                />
              </Link>
            </div>

            <div className={styles.contentContainer}>
              {!isPickPutPage && (
                <Link to="/pickout" className={styles.toPickOutContainer}>
                  <div className={styles.pickOutText}>買房</div>
                </Link>
              )}

              {viewModel.profile?.group === 'sales' ? (
                <div className={styles.serviceContainer} onClick={show}>
                  <img src={salesChatIcon} alt="salesChat" />
                  <div
                    className={clsx(
                      styles.notification,
                      styles.salesNotification,
                      unread !== 0 ? styles.show : null
                    )}
                  />
                </div>
              ) : (
                <>
                  <div
                    className={styles.serviceContainer}
                    onClick={() => {
                      if (viewModel.profile?.group === 'consumer') {
                        salesPopup.combineShowModal();
                      } else if (guestCSForm.isGuestLogin) {
                        salesPopup.combineShowModal();
                      } else {
                        guestCSForm.toggleOpen();
                      }
                    }}
                  >
                    <img src={salesChatIcon} alt="service" />
                    <div
                      className={clsx(
                        styles.notification,
                        styles.userNotification,
                        unread !== 0 ? styles.show : null
                      )}
                    />
                  </div>
                </>
              )}
              <Link to="/center">
                <img
                  src={profileImg}
                  alt="member"
                  className={styles.memberImg}
                />
              </Link>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

NavBar.propTypes = {
  history: PropTypes.object,
  viewModel: PropTypes.object,
  profile: PropTypes.object,
  location: PropTypes.object
};

NavBar.defaultProps = {
  history: null,
  viewModel: null,
  profile: null,
  location: null
};

export default withProfile(withRouter(NavBar), false);
