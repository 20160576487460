import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import MediaQuery from 'react-responsive';

import cSModule from 'src/services/csModule';

import ConversationClient from 'conversation-client';
import PropTypes from 'prop-types';
import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';
import QrCodeModal from '../CustomizeModal/QrCodeModal';
import AccountInfoModal from '../CustomizeModal/AccountInfoModal';


import styles from './styles.module.css';

@observer
class ConversationModule extends React.Component {

  componentDidUpdate() {
    const { profile } = this.props.appContext.state;

    if (!cSModule.client.current && profile) {
      cSModule.didUpdate(profile);
    }
  }

  componentWillUnmount() {
    cSModule.willUnmount();
  }

  render() {
    return (
      <>
        {
          cSModule.enable ? (
            <>
              {/* workaround for now: need to be fix using hook or other package */}
              <MediaQuery maxWidth={767} onChange={cSModule.handleChangeToSmall}>
                <>
                  {
                    !cSModule.isSmall && (<div style={{ display: 'none' }}>isSmall</div>)
                  }
                </>
              </MediaQuery>
              <div
                className={clsx(
                  styles.client,
                  cSModule.isSmall && styles.mobile,
                  !cSModule.visible && styles.minimal
                )}
              >
                <ConversationClient
                  ref={cSModule.client}
                  source="https://chat.maauu.com/core"
                  mobile={cSModule.isSmall}
                  visible={cSModule.visible}
                  token={cSModule.token}
                  groupId={cSModule.groupId}
                  onLoad={cSModule.onLoad}
                  onError={cSModule.onError}
                  onClose={cSModule.onClose}
                  onUnreadCountChanged={cSModule.onUnreadCountChanged}
                  onAnnouncementReceived={cSModule.onAnnouncementReceived}
                  onVideoCallIncoming={cSModule.onVideoCallIncoming}
                  onCartClicked={cSModule.onCartClicked}
                  onVoiceClicked={cSModule.onVoiceClicked}
                />
              </div>
              {
                cSModule.userIdentity === 'consumer'
                  ? (
                    <QrCodeModal
                      viewModel={cSModule.popupVm}
                    />
                  )
                  : null
              }
              {
                cSModule.userIdentity === 'sales'
                  ? (
                    <AccountInfoModal
                      viewModel={cSModule.popupVm}
                    />
                  )
                  : null
              }
            </>
          ) : null
        }
      </>
    );
  }
}

ConversationModule.propTypes = {
  appContext: PropTypes.object
};

ConversationModule.defaultProps = {
  appContext: null
};

export default withAppStateContext(ConversationModule);
