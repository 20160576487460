import { observable, action, computed, makeObservable, runInAction } from 'mobx';

import data from 'src/json/sections.json';
import { createSectionViewModel } from 'src/viewModels/Advertisement/Section/Factory';
import PopupsViewModel from 'src/viewModels/Popup/PopupsViewModel';
import { privacys } from 'src/constants';

export default class PageViewModel {
  @observable activeIndex = 0;
  @observable sectionVMs = [];
  @observable swiper = null;
  @observable popup = [];
  @observable isShareBtnShow = false;

  constructor(props) {
    makeObservable(this);

    this.deserialize();
    this.handleChangeSection = props;
  }

  @action
  deserialize = () => {
    const { sections } = data;
    // generate id.
    for (let i = 0; i < sections.length; i += 1) {
      sections[i].id = i;
    }
    this.sectionVMs = sections.map(createSectionViewModel);
    this.popup = new PopupsViewModel(privacys);
  }


  @action
  onSwiper = (swiper) => {
    this.swiper = swiper;
  };

  @action
  slideTo = (index) => {
    this.swiper.slideTo(index);
    const target = this.sectionVMs[index];
    if (target?.type === 'a') {
      this.slideToPopUpVideo(target);
    }
  };

  @action
  slideToPopUpVideo = async (target) => {
    // TODO: find index;
    try {
      const delay = (ms) => new Promise((r) => setTimeout(r, ms));
      await delay(500);
      runInAction(() => {
        target.openVideo();
      });
    } catch (error) {
      console.error(error);
    }
  }

  @action
  onSectionChange = (swiper) => {
    this.sectionVMs[swiper.activeIndex].onEnter();
    this.sectionVMs[this.activeIndex].onLeave();

    this.activeIndex = swiper.activeIndex;
    // work around: prevent different type of carousel ani.
    // TODO: insert slide index here.
    if (swiper.activeIndex === 0) {
      this.handleChangeSection('show', this.activeIndex);
    } else {
      this.handleChangeSection('hide', this.activeIndex);
    }

    // force renderBullet re-render
    swiper.pagination.render();
  };

  @computed
  get activeName() {
    const { sections } = data;
    return sections[this.activeIndex].name || '';
  }

  @computed
  get isSectionG() {
    return this.sectionVMs[this.activeIndex].type === 'g';
  }

  @action
  toggleShareBtn = () => {
    this.isShareBtnShow = !this.isShareBtnShow;
  }
}
