export default class Plan {
  constructor(params) {
    const {
      id,
      subdomain,
      name,
      title,
      isInLine,
      isActive,
      depositType,
      deposit,
      spaceCount
    } = params;

    this.id = id;
    this.subdomain = subdomain;
    this.name = name;
    this.title = title;
    this.isActive = isActive;
    this.isInLine = isInLine;
    this.depositType = depositType;
    this.deposit = deposit;
    this.spaceCount = spaceCount;
  }

  static fromRes(data) {
    return new Plan(data);
  }
}
