/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useCountDown from 'react-countdown-hook';
import Toastify from 'toastify-js';
import store from 'store2';
import cSModule from 'src/services/csModule';

import UserService from 'src/services/user';
import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import useProfile from 'src/hooks/useProfile';
import Popup from 'src/components/Popup';
import PopupsViewModel from 'src/viewModels/Popup/PopupsViewModel';
import {
  privacys,
  convertTelephoneText,
  creditCard,
  footerCopyRight
} from 'src/constants';
import Footer from 'src/components/Footer';
import { Theme } from 'src/components/Modal/themes';
import ErrorMessageService from 'src/services/errorMessage';

import OtpForm from 'src/components/OtpForm';
import common from '../common.module.css';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import styles from './styles.module.css';

const InitialTime = 300 * 1000;
const Interval = 1000;

export default function LoginPage() {
  const { actions } = useAppStateContext();
  const history = useHistory();
  const shouldAdd = history.location.state?.shouldAdd ?? false;
  const prePath = history.location.state?.target ?? '/center';
  const [timeLeft, { start }] = useCountDown(InitialTime, Interval);
  const [loading, setLoading] = useState(false);
  const [verifyClick, setVerifyClick] = useState(false);

  const [otpVerified, setOtpVerified] = useState(false);

  const [signUpToken, setSignUpToken] = useState('');

  const [tempPhone, setTempPhone] = useState('');
  const [id, setId] = useState('');
  const [step, setStep] = useState('step1');
  // step1: 發送驗證碼
  // step2: 手機驗證
  // step3: 填寫訂購人 or 註冊

  const popupVm = useRef(new PopupsViewModel(privacys));

  const profile = useProfile(false);

  useEffect(() => {
    if (profile && !shouldAdd) {
      history.replace('/center');
    }
  }, [profile, history]);

  const handleError = async (cb, errorService) => {
    try {
      await cb();
    } catch (err) {
      actions.showModal({
        theme: Theme.error,
        title: 'ERROR',
        content: errorService(err),
        okText: '確認',
        onOk: () => {}
      });
    }
  };

  const onVeriyClick = async (data) => {
    setLoading(true);
    await handleError(async () => {
      const { phone } = data;
      const number = convertTelephoneText(phone);
      await UserService.otp(number);
      setTempPhone(number);
      setStep('step2');

      // setId(number);
      start();
    }, ErrorMessageService.otp);
    setLoading(false);
    setVerifyClick(true);
  };

  const onInputChange = () => {
    // 重新驗證手機, 清除token, id
    setSignUpToken('');
    setId('');
    setVerifyClick(false);
  };

  const onOtpClick = async (data) => {
    setLoading(true);
    await handleError(async () => {
      const { otp } = data;
      const res = await UserService.login(tempPhone, otp);

      // 已註冊
      if (res.profile) {
        actions.updateProfile(res.profile);
        // NOTE: update cs module.
        if (cSModule.enable) {
          cSModule.end();
        }
        cSModule.didUpdate(res.profile);

        // 如果有按加入購物車
        if (shouldAdd) {
          await addToCart(res.profile);
          Toastify({
            text: '已加到購物車！',
            duration: 1500,
            gravity: 'bottom',
            position: 'center',
            offset: {
              x: 0,
              y: 30
            },
            style: {
              font: '600 16px "PingFang TC"',
              background: '#333',
              color: '#fff',
              letterSpacing: '1.2px',
              borderRadius: '5px'
            }
          }).showToast();

          const st = store();
          history.push(
            `/pickout?status=building&building=${st.buildingId}&floor=${st.floorId}&door=${st.doorId}`
          );
        } else {
          history.replace(prePath);
        }
      }

      // 未註冊
      setSignUpToken(res.token);
      setStep('step3');
      setOtpVerified(true);
      setId(tempPhone);
      setVerifyClick(false);
    }, ErrorMessageService.login);
    setLoading(false);
  };

  const onTimeUp = async () => {
    await handleError(async () => {
      await UserService.otp(id);
      start();
    }, ErrorMessageService.otp);
  };

  const onSignupClick = async (data) => {
    setLoading(true);
    await handleError(async () => {
      const req = {
        telephone: id,
        ...data
      };
      const res = await UserService.signup(req, signUpToken);
      // 成功註冊
      if (res.profile) {
        actions.updateProfile(res.profile);

        // NOTE: update cs module.
        if (cSModule.enable) {
          cSModule.end();
        }
        cSModule.didUpdate(res.profile);

        // 如果有按加入購物車
        if (shouldAdd) {
          await addToCart(res.profile);
          Toastify({
            text: '已加到購物車！',
            duration: 1500,
            gravity: 'bottom',
            position: 'center',
            offset: {
              x: 0,
              y: 30
            },
            style: {
              font: '600 16px "PingFang TC"',
              background: '#333',
              color: '#fff',
              letterSpacing: '1.2px',
              borderRadius: '5px'
            }
          }).showToast();

          const st = store();
          history.push(
            `/pickout?status=building&building=${st.buildingId}&floor=${st.floorId}&door=${st.doorId}`
          );
        }
      }
    }, ErrorMessageService.signup);

    setLoading(false);
  };

  const addToCart = async (p) => {
    const st = store();
    const preOrder = await UserService.createPreOrder(p.id, st.doorId);
    const obj = {
      style: st.design,
      garages: st.spaces
    };
    const newOrder = await UserService.updatePreOrder(p.id, preOrder.id, obj);
  };

  return (
    <div className="page">
      <div className={common.content}>
        <fieldset className={common.form}>
          <h2 className={common.topic}>會員登入與註冊</h2>

          <LoginForm
            onSubmit={onVeriyClick}
            onInputChange={onInputChange}
            loading={loading}
            verifyClick={verifyClick}
            prePath={prePath}
            seconds={timeLeft / 1000}
            onTimeUp={onTimeUp}
            signUpToken={signUpToken}
          />

          {verifyClick && (
            <OtpForm
              onSubmit={onOtpClick}
              seconds={timeLeft / 1000}
              onTimeUp={onTimeUp}
              loading={loading}
              btnText="送出"
            />
          )}

          {otpVerified && (
            <SignupForm
              onSubmit={onSignupClick}
              onPrivacyClick={() => {
                popupVm.current.createLists(privacys);
                popupVm.current.showPopup();
              }}
              onCreditCardClick={() => {
                popupVm.current.createLists(creditCard);
                popupVm.current.showPopup();
              }}
              loading={loading}
            />
          )}

          <Footer>
            <span>{footerCopyRight}</span>
            <span
              className={styles.privacy}
              onClick={popupVm.current.showPopup}
            >
              隱私權政策
            </span>
          </Footer>
          <Popup popupListVm={popupVm.current} />
        </fieldset>
      </div>
    </div>
  );
}
