import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ShareBtnViewModel from 'src/viewModels/ShareBtn';
import isMobile from 'ismobilejs';

import styles from './styles.module.css';

@observer
class ShareBtn extends React.Component {
  constructor(props) {
    super(props);

    this.viewModel = new ShareBtnViewModel();
  }
  render() {
    const { windowHeight } = this.props;
    const paginationHeight = document.querySelector('.swiper-pagination')?.getBoundingClientRect().height / 2;
    // distance between btns and pagination: 50
    const btnY = windowHeight * 0.5 + paginationHeight + 35;
    return (
      <div
        className={styles.btnContainer}
        style={{ top: btnY }}
      >
        <div
          className={styles.fb}
          onClick={this.viewModel.fbShare}
        />
        {
          !isMobile(window.navigator).phone
            ? (
              <div
                className={styles.line}
                onClick={this.viewModel.lineShare}
              />
            )
            : (
              <a
                href={this.viewModel.lineShareHref}
              >
                <div
                  className={styles.line}
                />
              </a>
            )
        }
        <div
          className={styles.copyLink}
          onClick={this.viewModel.copyLink}
        />
      </div>
    );
  }
}

ShareBtn.propTypes = {
  windowHeight: PropTypes.number
};

ShareBtn.defaultProps = {
  windowHeight: 0
};

export default ShareBtn;
