import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import closeBtn from 'src/assets/btn_close2@btnClose.png';
import CustomizeModal from '..';

import styles from './styles.module.css';


@observer
class AccountInfoModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { viewModel } = this.props;
    const { userInfo } = viewModel;

    return (
      <CustomizeModal
        shouldIgnorePuppeteer
        className={clsx(styles.modal, viewModel.isShow ? styles.show : styles.hide)}
      >
        <div className={styles.outerContainer}>
          <div className={styles.title}>
            帳號詳情
            <div onClick={viewModel.hideModal}>
              <img
                src={closeBtn}
                alt="close"
                className={styles.closeBtn}
              />
            </div>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.userDetailContainer}>
              <div className={styles.detailTitle}>用戶資訊</div>
              <div className={styles.userDetailGrid}>
                <div className={styles.key}>用戶姓名</div>
                <div className={styles.key}>電子郵件</div>
                <div className={styles.key}>手機號碼</div>
                <div className={styles.value}>{userInfo.name}</div>
                <div className={styles.value}>{userInfo.email}</div>
                <div className={styles.value}>{userInfo.phone}</div>
              </div>
            </div>
            <div className={styles.cartDetailContainer}>
              <div className={styles.detailTitle}>購物車一覽</div>
              <div className={styles.cartDetailBox}>
                {
                  userInfo.purchase.map((el) => {
                    return (
                      <div
                        className={styles.purchaseDetail}
                        key={el.id}
                      >
                        {/* TODO: loop for clean code. */}
                        <div className={styles.key}>訂單編號</div>
                        <div className={styles.value}>{el.id}</div>
                        <div className={styles.key}>訂購人姓名</div>
                        <div className={styles.value}>{el.ordererName}</div>
                        <div className={styles.key}>狀態</div>
                        <div className={styles.value}>{el.status}</div>
                        <div className={styles.key}>電子信箱</div>
                        <div className={styles.value}>{el.email}</div>
                        <div className={styles.key}>最後更新</div>
                        <div className={styles.value}>{el.dateString}</div>
                        <div className={styles.key}>生日</div>
                        <div className={styles.value}>{el.birthday}</div>
                        <div className={styles.key}>總價</div>
                        <div className={styles.value}>{el.amount}</div>
                        <div className={styles.key}>聯絡電話</div>
                        <div className={styles.value}>{el.phone}</div>
                        <div className={styles.key}>內容</div>
                        <div className={styles.value}>{el.building}</div>
                        <div className={styles.key}>聯絡地址</div>
                        <div className={styles.value}>{el.address}</div>
                        <div className={styles.key}>興趣風格</div>
                        <div className={styles.value}>{el.style}</div>
                        <div className={styles.key}>性別</div>
                        <div className={styles.value}>{el.gender}</div>
                        <div className={styles.key}>職業別</div>
                        <div className={styles.value}>{el.job}</div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </CustomizeModal>
    );
  }
}

AccountInfoModal.propTypes = {
  viewModel: PropTypes.object
};

AccountInfoModal.defaultProps = {
  viewModel: null
};

export default AccountInfoModal;
