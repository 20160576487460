import React from 'react';
import PropTypes from 'prop-types';
import {
  Document,
  Page,
  StyleSheet,
  Font
} from '@react-pdf/renderer';

import Receipt from 'src/models/responses/Receipt';
import Profile from 'src/models/responses/Profile';
import CustomFont from 'src/assets/SweiDevilLegCJKtc-Regular.ttf';
import Header from './components/Header';
import Banner from './components/Banner';
import Location from './components/Location';
import About from './components/About';
import Orderer from './components/Orderer';
import Form from './components/Form';
import Footer from './components/Footer';

const ReceiptV2Page = (props) => {
  const { order, trade } = props.receipt ?? {};

  const profile = Profile.fromData(props.profile);

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <Header plan={props.plan} order={order} />
        <Banner />
        <About order={order} />
        <Location />
        <Orderer orderer={order?.ordererInfo} />
        <Form />
        <Footer />
      </Page>
    </Document>
  );
};

ReceiptV2Page.propTypes = {
  plan: PropTypes.object.isRequired,
  receipt: PropTypes.instanceOf(Receipt).isRequired,
  profile: PropTypes.object.isRequired
};

/**
 * /public/fonts/
 */

Font.register({
  family: 'custom', // custom name
  fontWeight: 'normal',
  fontStyle: 'normal',
  src: CustomFont
});

// Font.register({
//   family: 'custom', // custom name
//   fonts: [
//     {
//       src: './fonts/NotoSansTC-Light.otf',
//       fontWeight: 300
//     },
//     {
//       src: './fonts/NotoSansTC-Medium.otf',
//       fontWeight: 400
//     },
//     {
//       src: './fonts/NotoSansTC-Regular.otf',
//       fontWeight: 500
//     },
//     {
//       src: './fonts/NotoSansTC-Bold.otf',
//       fontWeight: 700
//     }
//   ]
// });

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF'
  }
});

export default ReceiptV2Page;
