import Cookies from 'js-cookie';

import API from 'src/apis';
import { AUTH_TOKEN_KEY } from 'src/constants';
import Login from 'src/models/responses/Login';
import Profile from 'src/models/responses/Profile';
import Order from 'src/models/responses/Order';
import Signup from 'src/models/responses/Signup';
import PreOrder from 'src/models/responses/PreOrder';
import Sale from 'src/models/responses/Sale';
import Receipt from 'src/models/responses/Receipt';
import Trade from 'src/models/responses/Trade';

import AuthService from './auth';

export default class UserService {
  static async login(id, password, setToken = true) {
    const { data } = await API.user.login(id, password);
    const res = Login.fromRes(data);

    // 若只需要Otp驗證， 不需要set token
    if (setToken) {
      Cookies.set(AUTH_TOKEN_KEY, res.token);
    }
    return res;
  }

  static async signup(req, token) {
    const { data } = await API.user.signup(req, token);
    const res = Signup.fromRes(data);
    Cookies.set(AUTH_TOKEN_KEY, res.token);
    return res;
  }

  static async logout() {
    try {
      const token = Cookies.get(AUTH_TOKEN_KEY);
      Cookies.remove(AUTH_TOKEN_KEY);
      if (token) {
        await API.user.logout(token);
      }
    } catch (err) {
      console.log(err); // ignore error
    }
  }

  static async otp(number) {
    const { data } = await API.user.otp(number);
    return data;
  }

  static async getMyProfile() {
    const { data } = await API.user.myProfile(AuthService.getToken());
    return Profile.fromRes(data.profile);
  }

  static async getUserProfile(uid) {
    const { data } = await API.user.userProfile(AuthService.getToken(), uid);
    return Profile.fromRes(data.profile);
  }

  static async updateProfile(uid, req) {
    const { data } = await API.user.updateProfile(AuthService.getToken(), uid, req);
    return Profile.fromRes(data.profile);
  }

  static async createPreOrder(uid, doorId) {
    const { data } = await API.user.createPreOrder(AuthService.getToken(), uid, doorId);
    return PreOrder.fromRes(data);
  }

  static async getPreOrder(uid, pid) {
    const { data } = await API.user.getPreOrder(AuthService.getToken(), uid, pid);
    return PreOrder.fromRes(data.preorder);
  }

  static async updatePreOrder(uid, pid, req) {
    await API.user.updatePreOrder(AuthService.getToken(), uid, pid, req);
  }

  static async deletePreOrder(uid, pid) {
    await API.user.deletePreOrder(AuthService.getToken(), uid, pid);
  }

  static async getOrders(uid) {
    const { data: { orders } } = await API.user.getOrders(AuthService.getToken(), uid);
    return orders.map((order) => order.type === 'preorder' ? PreOrder.fromRes(order) : Order.fromRes(order));
  }

  static async getOrderReceipt(uid, oid) {
    const res = await API.user.getOrderReceipt(AuthService.getToken(), uid, oid);
    return Receipt.fromRes(res.data);
  }

  static async getOrder(uid, oid) {
    const { data } = await API.user.getOrder(AuthService.getToken(), uid, oid);
    return Order.fromRes(data?.order);
  }

  static async createEcPayForm(uid, pid, price, spacesPrice) {
    const { data } = await API.user.createEcPayForm(AuthService.getToken(), uid, pid, price, spacesPrice);
    return data;
  }

  static async getSales() {
    const { data: { list } } = await API.user.sales(AuthService.getToken());
    return list.map((el) => Sale.fromRes(el));
  }

  static async getTrades(doorId) {
    const { data: { trades } } = await API.user.trades(AuthService.getToken(), doorId);
    return trades.map((el) => Trade.fromRes(el));
  }
}
