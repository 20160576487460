import { request, getHost } from './utils';

export const status = (token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/plans/status`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const settings = (token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/plans/settings`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const statistics = (token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/plans/statistics`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const info = () => {
  const option = {
    method: 'get',
    url: `${getHost()}/api/v1/plans/info`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };
  return request(option);
};

export const labelsPools = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/plans/labels/pools`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };
  return request(options);
};

export const doorSlots = (did) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/plans/door/${did}/slots`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };
  return request(options);
};
