import React, { forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

function Image(props, ref, isLazy) {
  const { value } = props;

  useImperativeHandle(ref, () => ({
    onEnter() { },
    onLeave() { }
  }));

  return (
    <>
      {
        isLazy
          ? <img data-src={value} alt="building" className={clsx(styles.layout, 'swiper-lazy')} />
          : <img src={value} alt="building" className={clsx(styles.layout)} />
      }
    </>
  );
}


export default forwardRef(Image);
