import React from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Lazy } from 'swiper';
import { withRouter } from 'react-router';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Footer from 'src/components/Footer';
import Popup from 'src/components/Popup';
import ShareBtn from 'src/components/ShareBtn';
import { footerCopyRight } from 'src/constants';
import { createSectionView } from './Factory';
import styles from './styles.module.css';

SwiperCore.use([Lazy]);

@observer
class MobileIndex extends React.Component {
  constructor(props) {
    super(props);
    this.viewModel = props.viewModel;
    this.slideToIndex = this.props.location.state?.slide;
  }

  componentDidMount() {
    // if (this.slideToIndex && this.props.history.action === 'PUSH') {
    //   this.viewModel.slideTo(this.slideToIndex);
    // }
  }

  componentWillUnmount() {
    this.viewModel.handleChangeSection('show', 0);
  }

  render() {
    return (
      <div className={clsx('adPageM', styles.page)}>
        <Swiper
          direction="vertical"
          className="swiper"
          onActiveIndexChange={this.viewModel.onSectionChange}
          onSwiper={this.viewModel.onSwiper}
          cssMode
          lazy
          preloadImages={false}
        >
          {this.viewModel.sectionVMs?.map((el) => (
            <SwiperSlide
              // FIXME: use name for key
              className={styles.test}
              key={el.id}
            >
              {createSectionView(el, this.viewModel.swiper)}
            </SwiperSlide>
          ))}
        </Swiper>

        <div className={styles.action_btn_container}>

          <div
            className={clsx(
              styles.shareLink
            )}
            onClick={this.viewModel.toggleShareBtn}
          />

          <div
            className={clsx(
              styles.share_btns_container,
              this.viewModel.isShareBtnShow && styles.share_btns_container_show
            )}
          >
            <ShareBtn />
          </div>
        </div>

        {this.viewModel.activeIndex === this.viewModel.sectionVMs.length - 1 ? (
          <>
            <Footer className={styles.footer}>
              <span>{footerCopyRight}</span>
              <span
                className={styles.privacy}
                onClick={this.viewModel.popup.showPopup}
              >
                隱私權政策
              </span>
            </Footer>
            <Popup popupListVm={this.viewModel.popup} />
          </>
        ) : null}
      </div>
    );
  }
}

MobileIndex.propTypes = {
  viewModel: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};

MobileIndex.defaultProps = {
  viewModel: null,
  location: null,
  history: null
};

export default withRouter(MobileIndex);
