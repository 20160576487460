import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  View,
  StyleSheet
} from '@react-pdf/renderer';

import FormImage from 'src/assets/receipt_form.png';

const Form = (props) => {
  return (
    <View style={styles.form} break>
      <Image
        style={styles.img}
        source={FormImage}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  form: {
    display: 'flex',
    margin: '50px 40px 0px 40px'
  },
  img: {
  }
});


export default Form;
