export const jobOptions = [
  { label: '一技之長', value: '' },
  { label: '電腦資訊人', value: '電腦資訊人' },
  { label: '財富自由金融保險', value: '財富自由金融保險' },
  { label: '政府公家飯', value: '政府公家飯' },
  { label: '科技趨勢與商業脈動', value: '科技趨勢與商業脈動' },
  { label: '仁心醫療', value: '仁心醫療' },
  { label: '法律審判', value: '法律審判' },
  { label: '百貨零售購物車', value: '百貨零售購物車' },
  { label: '管理專業顧問', value: '管理專業顧問' },
  { label: '自由貿易進出口', value: '自由貿易進出口' },
  { label: '海陸空交通', value: '海陸空交通' },
  { label: '出版業的重生', value: '出版業的重生' },
  { label: '瘋狂廣告與媒體', value: '瘋狂廣告與媒體' },
  { label: '食物鏈農漁牧', value: '食物鏈農漁牧' },
  { label: '時間自由業', value: '時間自由業' },
  { label: '學生我最大', value: '學生我最大' }
];

