import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Mousewheel, Lazy } from 'swiper';
import { withRouter } from 'react-router';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ShareBtn from 'src/components/ShareBtn';
import { withResizeDetector } from 'react-resize-detector';

import './customizeSwiper.css';
import { footerCopyRight } from 'src/constants';
import Footer from 'src/components/Footer';
import Popup from 'src/components/Popup';
import { createSectionView } from './Factory';
import styles from './styles.module.css';

SwiperCore.use([Pagination, Mousewheel, Lazy]);

@observer
class WebAdvertisement extends Component {
  constructor(props) {
    super(props);
    this.viewModel = props.viewModel;
    this.slideToIndex = this.props.location.state?.slide;
  }

  _renderBullet = (index, className) => {

    const bulletStyle = this.viewModel.activeIndex === index
      ? 'background-color:#2C2C2C;width:8px;height:8px;border: 1px solid #2C2C2C;'
      : 'background-color:#F5F5F5;width:8px;height:8px;border: 1px solid #2C2C2C;';


    return `<div class="${className}" style="
    margin: 0;
    margin-right: 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    border-radius: 0;
    opacity: 1;
    width:auto;
    height:auto;
    ">
    <div>${
      index === this.viewModel.activeIndex ? this.viewModel.activeName : ''
    }</div>
    <div class="custom-bullet" ></div>
    </div>
    `;
  };


  componentDidMount() {
    // prevent index = 0 didn't trigger slideTo func.
    // if (this.slideToIndex !== '' && this.props.history.action === 'PUSH') {
    //   this.viewModel.slideTo(this.slideToIndex);
    // }
    this.viewModel.swiper.$wrapperEl[0].classList.add(
      styles.customizeSlideEffect
    );
  }

  render() {
    return (
      <div className={clsx('adPageW', styles.page)}>
        <Swiper
          direction="vertical"
          pagination={{
            clickable: true,
            renderBullet: this._renderBullet
          }}
          className={clsx('swiper')}
          onActiveIndexChange={this.viewModel.onSectionChange}
          onSwiper={this.viewModel.onSwiper}
          mousewheel={{
            forceToAxis: true,
            releaseOnEdges: true,
            thresholdDelta: 10
          }}
          touchReleaseOnEdges
          threshold={this.viewModel.isSectionG ? 30 : 10}
          speed={1200}
          preloadImages={false}
          lazy
        >
          {this.viewModel.sectionVMs?.map((el) => (
            <SwiperSlide key={el.id}>{createSectionView(el)}</SwiperSlide>
          ))}
        </Swiper>
        {this.viewModel.activeIndex === this.viewModel.sectionVMs.length - 1 ? (
          <>
            <Footer className={styles.footer}>
              <span>{footerCopyRight}</span>
              <span
                className={styles.privacy}
                onClick={this.viewModel.popup.showPopup}
              >
                隱私權政策
              </span>
            </Footer>
            <Popup popupListVm={this.viewModel.popup} />
          </>
        ) : null}
        {
          this.viewModel.swiper && (
            <ShareBtn
              windowHeight={this.props.height}
            />
          )
        }
      </div>
    );
  }
}

WebAdvertisement.propTypes = {
  viewModel: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};

WebAdvertisement.defaultProps = {
  viewModel: null,
  location: null,
  history: null
};

export default withResizeDetector(withRouter(WebAdvertisement));
