import React from 'react';

export default function Privacy() {
  return (
    <div>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第一條	基本條款</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本隱私權聲明適用於細細生活網路科技股份有限公司(下稱本公司)於www.subtle-net.com網站域名及次級網域名(下稱本網站)所提供的服務(下稱本服務)。當您瀏覽及使用本網站時，即表示您同意本公司收集、使用、公布和以其他形式運用您的個人資料，且同意受本隱私權聲明條款及條件所拘束，本隱私權聲明並為會員條款之一部分。如您不同意，請勿使用或取得本公司所提供之服務。
二. 您同意本公司可不時對本隱私權聲明作出修訂，以符合法規變更、個人資料收集與使用政策的改變、網站功能異動或技術更新。本公司所為修訂將會發布在本隱私權聲明頁面上，並自初次發布之日起7天後自動生效。因此，您應該定期閱覽隱私權聲明，以了解本公司最新之政策。如您不同意本隱私權聲明的任何變更或修訂，請勿繼續使用本網站。倘若您在條款修訂後繼續使用本網站，將被視作同意該修訂條款。
三. 本網站亦提供外部網站之網路連結，若您進入該連結，您將會離開本網站，而您後續提供給第三方網站之個人資料，或第三方網站所蒐集、處理及利用之個人資料，皆非屬本聲明涵蓋之範圍。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第二條	個人資料蒐集、處理及利用告知暨同意</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本公司根據下列特定目的之服務，將蒐集、處理及利用您的個人資料：
      (一) ○四○：行銷
      (二) ○六三：非公務機關依法定義務所進行個人資料之蒐集處理及利用
      (三) ○六九：契約、類似契約關係或其他法律關係事務
      (四) ○九○：消費者、客戶管理與服務
      (五) ○九一：消費者保護事務
      (六) ○九八：商業與技術資訊
      (七) 一二九：會計與相關服務
      (八) 一三五：資(通)訊服務
      (九) 一三六：資(通)訊與資料庫管理
      (一〇) 一三七：資通安全與管理
      (一一) 一四八：網路購物及其他電子商務服務
      (一二) 一五○：輔助性與後勤支援管理
      (一三) 一五二：廣告或商業行為管理
      (一四) 一五七：調查、統計與研究分析
      (一五) 一八一：其他經營合於營業登記項目或組織章程所定之業務
二. 本公司蒐集之個人資料類別：
      (一)	C001辨識個人者
      (二)	C002辨識財務者
      (三)	C011個人描述
      (四)	C031住家及設施
      (五)	C032財產
      (六)	C036生活格調
      (七)	C038職業
      (八)	C052資格或技術
      (九)	C132未分類之資料
三. 本公司利用個人資料之期間、地區、對象如下：
      (一)	期間：依上開特定目的存在之期間，本公司考量不再使用所蒐集之個人資料或上開特定目的消滅後定期刪除。
      (二)	地區：本公司及經本公司授權之人員因執行上開目的之所在地。
      (三)	對象：本公司及本公司授權之人員。 
四. 本公司將依以下之目的及方式使用您的個人資料：
      (一)	本公司將使用您建立之個人資料檔案中的資料及您在本網站上的行為所取得之其他資料，來解決爭議、檢修障礙問題及執行會員合約，以利提供更佳之服務。 
      (二)	本公司將使用您的個人資料以加強本公司市場行銷及促銷、會員行為模式之統計分析、市場調查及內部研究，並據此規劃或調整本網站之內容、網頁設計及服務。
      (三)	您同意本公司可以任何方式傳送本網站及本公司其他網站之各項行銷或活動訊息給您。若您不同意接受上開各行銷或活動訊息之傳送及利用，您可直接登入會員帳戶勾選不同意，本網站即不再對您為任何行銷或活動訊息之傳送及利用。
五. 就本公司保有之個人資料，您日後仍可為適當說明後，向本公司查詢、請求閱覽、製給複製本、補充或更正、請求停止蒐集、處理或利用，以及刪除之。本公司就上述查詢、請求閱覽及製給複製本，得酌收必要之成本費用，惟如因您請求上述作為造成權益受損，本公司不需負擔損害賠償責任。
六. 如您欲行使前項之權利，可向本公司客服提出申請(信箱：)。
七. 除法律規定外，您得自由選擇是否提供相關個人資料及類別，惟倘您所拒絕提供者乃本公司辦理服務所需之資料，本公司可能無法進行必要之作業而無法為您提供相關服務。
八. 	本公司將遵守法規及相關標準，盡力以合理之技術及程序，保障您的個人資料安全。唯有本公司授權之人員才能接觸您的個人資料，倘有違反保密義務者，將受到相關之處分。
九. 經本公司告知上開事項，您已清楚了解上開告知事項，並同意依據上開所述，提供個人資料予本公司。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第三條	與第三人共用之政策</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`一. 本網站絕不會任意提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
二. 前項但書之情形包括但不限於：
      (一)	經由您書面同意。
      (二)	法律明文規定。
      (三)	為免除您生命、身體、自由或財產上之危險。
      (四)	為執行本服務，本公司將提供會員之個人資料予使用本網站之建商代銷端及本網站合作之第三方支付平台。
      (五)	與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。
      (六)	為保護本公司免於法律責任、回應政府機關來函、配合司法程序或司法調查以及其他必要之情形。
      (七)	當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
      (八)	有利於您的權益。
      (九)	本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第四條	Cookies</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`為提供您最佳的服務，本網站會使用Cookie技術，以分析網頁的流動狀況、衡量促銷活動的效果，並增進信任度及安全性。Cookie是網站伺服器用來和您的瀏覽器進行溝通之一種技術，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中進行設定並拒絕Cookie的寫入，但此舉亦可能導致本網站某些功能無法正常執行。

`
        }
      </p>
      <h3 style={{ fontSize: 24, lineHeight: '36px' }}>第五條	其他公司組織</h3>
      <p style={{ fontSize: 14, lineHeight: '32px' }}>
        {
`倘若本公司日後與其他企業組織合併或被收購，於此情形，您可預見本公司會將您提供給本公司之資料轉交他人以便繼續提供本網站及本服務，本公司亦將通知新結合的組織遵循本隱私權聲明之規定。
`
        }
      </p>
    </div>
  );
}
