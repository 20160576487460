import React from 'react';
import { observer } from 'mobx-react';
import { createPortal } from 'react-dom';

import CsBtn from './csBtn';
import ChooseSalesPopup from './ChooseSalesPopup';
import GuestCSForm from './GuestCSForm';
import ConversationModule from './ConversationModule';

const CommBundle = observer(() => {
  return (
    <div id="bundle">
      <CsBtn />
      <ChooseSalesPopup />
      <GuestCSForm />
      <ConversationModule />
    </div>
  );
});

export default CommBundle;
