import React from 'react';

import avatar1 from 'src/assets/avatar1.jpg';
import avatar2 from 'src/assets/avatar2.jpg';
import avatar3 from 'src/assets/avatar3.jpg';
import avatar4 from 'src/assets/avatar4.jpg';
import avatar5 from 'src/assets/avatar5.jpg';
import avatar6 from 'src/assets/avatar6.jpg';
import avatar7 from 'src/assets/avatar7.jpg';
import avatar8 from 'src/assets/avatar8.jpg';
import purchaseFlowImg from 'src/assets/purchase_flow.jpg';
import purchaseFormImg from 'src/assets/purchase_form.png';
import Privacy from 'src/components/Privacy';
import SignupRule from 'src/components/SignupRule';

export const phoneRule = (value) => {
  if (value[0] === '0') {
    return /\d{10}/.test(value);
  }
  return /^9\d{8}$/.test(value);
}

export const convertTelephoneText = (text) => {
  return text[0] === '0'
  ? `+886${text.slice(1)}`
  : `+886${text}`;
}


export const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8];

export const SectionType = {
  A: 'a',
  B1: 'b1',
  B2: 'b2',
  B3: 'b3',
  C: 'c',
  D: 'd',
  E: 'e',
  F: 'f',
  G: 'g',
  H: 'h'
};

export const FieldType = {
  Image: 'image',
  Carousel: 'carousel',
  Iframe: 'iframe',
  Video: 'video',
  YouTube: 'youtube'
};

export const AUTH_TOKEN_KEY = 'client_token';

export const PickOutStatus = {
  building: 'building',
  floor: 'floor',
  door: 'door',
  car: 'car',
  carArea: 'carArea',
  carSpace: 'carSpace',
  design: 'design'
};

export const PickOutStatusDisplay = {
  building: '棟別',
  floor: '樓層',
  door: '戶號',
  carArea: '車樓',
  carSpace: '車位',
  design: '風格'
};

export const PreOrderStatus = {
  style: 'style',
  form: 'form'
};

export const MAX_TRANSACTION = 3;
// TODO: get api to update MAX_TRANSACTION

export const PopupType = {
  TypePicText: 'pictext',
  TypeText: 'text',
  TypePic: 'pic',
  TypeChildren: 'children'
};

export const privacys = [
  {
    type: 'children',
    title: '隱私權條款',
    children: <Privacy />
  }
];

export const signupRule = (isConfirm, onConfirm) => (
  [
    {
      type: 'children',
      title: '本網站核心功能即將為您開啟，請閱讀說明並註冊會員，讓我們提供您最好的服務!',
      children: <SignupRule isConfirm={isConfirm} onConfirm={onConfirm} />
    }
  ]
);

export const purchaseFlows = [
  {
    type: 'pic',
    image: purchaseFlowImg,
    imgSize: 'contain'
  },
  {
    type: 'pic',
    image: purchaseFormImg,
    imgSize: 'contain'
  }
];


export const creditCard = [
  {
    type: 'text',
    title: '優先磋商權與信用卡預授權',
    description: `本網站之服務旨在符合國家政策，協助賣方詳盡揭露物件資訊，協助消費者取得物件之優先磋商權力。
    本網站與消費者之間並無買賣對價關係，僅以信用卡預授權功能，確認其商談意願、媒合買賣雙方，消費者完成下單後將有專員與您聯繫，並依照內政部規定，處理後續購屋流程。

    預授權手續將保留您一萬元信用額度，不論雙方商談結果，預授權都將於五日內自動取消，並依其信用卡銀行作業標準，歸還信用額度，您無須支付任何費用。`
  }
];

export const consumerRule = [
  {
    type: 'text',
    title: '消費者會員條款-細細生活',
    description: `壹. 基本條款
一. 本會員服務條款所稱之「會員」為依照本網站(網址：)(下稱本網站)所定之程序完成加入會員並通過細細生活網路科技股份有限公司(下稱本公司)認證者。
二. 當會員完成會員註冊手續或開始使用本網站服務時，即表示已閱讀、瞭解並同意接受本會員條款之所有內容，並接受本服務相關衍生的服務項目及內容。
三. 本公司保有審核加入會員資格之權利，就已加入會員者，本公司亦保有解除其會員資格之權利。
四. 本公司有權於任何時間修改或變更本會員條款之內容，修改後的會員條款內容將公佈於網站上，會員應隨時注意該等修改或變更，本公司不於修改或變更後個別通知會員。會員於條款做任何修改或變動後繼續使用本服務時，視為會員已閱讀、瞭解並同意接受該等修改或變更。若不同意上述的會員條款修訂或更新方式，或不接受本會員條款的其他任一約定，會員應立即停止使用本服務。

貳. 服務申請及資料提供
一. 會員應為法人或其所在國家或地域之法定成年人，始得註冊成為本網站之會員。
二. 倘本公司就會員註冊時提供之資料存有疑問，得暫時停止會員權利並要求會員提供相關證明文件確認身分、資格，經本公司確認無誤後，會員方可繼續使用本服務。
三.	若會員提供任何錯誤或不實的資料、或未按指示提供資料、或欠缺必要之資料時，本公司有權不經事先通知，逕行暫停或終止會員的帳號，並拒絕會員使用本服務之全部或一部。若因此造成本公司受有任何損害，會員應負賠償之責。

參. 使用及異動
一.	本網站僅係本公司為建商或代銷公司(下稱建商代銷端)所指定建案設置之網頁，會員瞭解並同意遵守以下之使用約定：
      (一)	倘會員透過本網站點選客服服務，將與建商代銷端安排之客服人員進行溝通或視訊通話，與本公司無涉。
      (二)	倘會員於本網站點選「優先磋商」，會員得為信用卡預授權並依預授權成功之先後順序，取得優先與建商代銷端就該建案為洽談、磋商之權利。建商代銷端將於第一順位預授權成功之____日內，主動聯絡已取得第一順位優先磋商權者。倘經建商代銷端聯絡而未得會員之回應或於____日內洽談未果者，建商代銷端將取消會員之優先磋商權。
二.	倘會員有違反前項約定之情事，會員應負賠償之責。

肆. 會員帳號管理
一.	會員於本網站填列手機號碼後，本網站將以簡訊發送認證碼至會員填列之手機號碼以供認證。完成認證後，會員始得於本網站填寫資料並完成註冊程序。
二.	會員完成註冊程序後，會員帳號即為經認證之手機號碼，會員往後得於本網站輸入會員帳號並經簡訊認證即可登入本網站。
三.	會員之帳號、密碼及相關權益，均僅供會員使用及享有，不得轉借、轉讓他人或與他人合用。
四.	會員應維持帳號之機密安全。會員對自身之帳號因管理不善所生之結果應自行負責。
五.	任何依照規定方法輸入會員帳號及認證碼而與登入資料一致時，無論是否由會員輸入，均視為會員所使用，利用該帳號所進行之一切行為，會員應負完全責任。若因該帳號為非會員或非經會員授權之員工使用而造成本公司之損害，會員應負賠償之責。
六.	倘會員之帳號遭他人盜用或不當使用，除證明係因可歸責於本公司之事由所致者，本公司對於衍生之損害不負任何責任。
七.	本公司知悉會員之帳號確係遭他人冒用或盜用時，得立即暫停該帳號之使用，並暫停提供本服務。若係可歸責於會員而遭他人冒用或盜用，因而造成本公司受有損害者，本公司可請求會員賠償所受損害。

伍. 服務之維持與中斷
一.	本公司依一般合理之技術及方式，維持本網站及本服務之正常運作。
二.	於以下各項情況，本公司有權中止、中斷提供本服務及建案網頁之全部或一部，且對會員之任何損害均不負賠償或補償之責任：
      (一)	為維護良好服務品質及遇有系統故障，本服務相關軟硬體設備進行檢修或障礙排除工作，包括但不限於搬遷、更換、升級、保養或維修時。
      (二)	發生突發性之電子通信設備故障時。
      (三)	本服務及建案網頁所使用之電子通信服務因任何原因被停止，無法提供服務時。
      (四)	因天災等不可抗力因素或其他不可歸責於本公司致使無法提供本服務時。
      (五)	其他不可歸責於本公司之事由所致之本服務停止或中斷。


陸. 服務之終止
一.	倘本公司因業務縮減、技術上限制或其他因素致無法提供本服務，得於預定終止本服務之日前15日於本網站或以電子郵件通知會員，會員不得異議或要求任何補償或賠償。
二.	倘發生以下各項情況者，本公司得不經事前通知，即終止本服務：
      (一)	有相當事證足認會員利用本服務及建案網頁為詐欺等犯罪行為或不法行為者。
      (二)	會員提供虛偽不實之資料或證明文件，經查證屬實者。
      (三)	違反本會員條款且情節重大者。

柒. 免責聲明
一.	本服務僅係為建商代銷端指定之建案架設網頁並供會員瀏覽，不涉入會員與建商代銷端間之任何實際買賣或交易行為。
二.	本網站無涉於任何房屋買賣、預約之交易行為，僅使會員取得優先與建商代銷端進行磋商或洽談之權利，無涉任何買賣、給付或付款。實際之付款、預約、交貨等交易行為乃會員與建商代銷端即買賣雙方間為履行交易所生的行為，概與本公司無涉。
三.	本網站僅供會員進行信用卡預授權，此預授權將於3日後自動取消並依本網站合作之第三方支付平台及會員使用之信用卡發行銀行所定期限及作業標準歸還預授權所占用之信用額度。倘因會員使用之信用卡發卡銀行之疏失而有任何爭議，本公司概不負責。
四.	會員與建商代銷端間交易所衍生之糾紛，應由會員與建商代銷端自行處理，本公司概不負責。

捌. 資料之蒐集、處理及利用
一.	會員為使用本服務所提供之資料，本公司均將妥善保管並予以保密，並保護此等資料免於滅失或任何未經授權之存取。非經會員同意，本公司不會洩漏或交付予他人。本條保密義務，不因本合約之終止而失效。
二.	會員使用本服務時，即同意本公司依據「隱私權聲明」進行會員個人資料的蒐集、處理與利用。

玖. 智慧財產權約款
一.	本公司之商標、服務標誌、商業名稱、徽標、網域名稱以及本服務品牌的其他特色，均為本公司之獨有財產。本會員條款並未授予會員使用上開本公司財產之任何權利。
二.	本服務與建案網頁所使用之產品、軟體、圖片、程式、網站上所有內容，包括但不限於著作、文字、圖片、照片、音訊、影片、圖表、色彩組合、網站架構、版面設計等，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。
三.	若會員欲引用或轉載前述軟體、程式或網站內容，必須依法取得本公司或其他權利人之事前書面同意。如有違反，會員應對本公司負損害賠償責任。
四.	會員同意且擔保其在使用本服務時，不為侵害他人智慧財產權之行為。
五.	若會員違反上述之約定而有涉及侵權之情事，本公司可暫停全部或部分之服務，或逕自以取消會員帳號之方式處理，且若因而造成損害本公司之損害權益時，本公司並可請求損害賠償。

壹拾. 準據法與管轄權約定
一.	本會員條款之任何條款之全部或一部無效時，不影響其他約定之效力。
二.	本會員條款之解釋與適用，及本會員條款所生之一切爭議或糾紛，以中華民國法律為準據法，並以臺灣臺北地方法院為第一審管轄法院。

    `
  }
];

export const bankRate = [
  {
    name: '中國信託',
    rate: 1.87
  },
  {
    name: '台灣銀行',
    rate: 1.685
  },
  {
    name: '國泰世華',
    rate: 1.81
  }
];

export const footerCopyRight = '2022 subtle© All Rights Reserved. v1.01';
